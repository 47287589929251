@use "variables" as *;
@use "mixins" as *;
@use "typography";

.total-result {
  margin: 26px 0;
  padding: 32px;
  color: $color-white;
  background: $color-black;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 56px;
    top: -30px;
    width: 329px;
    height: 486px;
    background: url("../images/result-splash.svg") no-repeat;
    background-size: contain;
    z-index: -1;
  }

  h2 {
    margin: 0 0 8px;
  }

  h3 {
    font-weight: 400;
    margin: 0 0 16px;
  }

  .total-result__saving {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;

    span {
      @extend .small-text;
      vertical-align: middle;
    }
  }

  .total-result__emission {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-top: 8px;

    span {
      @extend .small-text;
      vertical-align: middle;
    }
  }

  .total-result__extra {
    margin-top: 24px;
  }

  @include tablet {
    flex: 0 1 494px;
    max-width: 100%;

    &:before {
      background-image: url("../images/result-splash--portrait.svg");
      top: auto;
      left: auto;
      right: -84px;
      bottom: -84px;
      width: 289px;
      height: 344px;
    }

    .total-result__savings {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      column-gap: 16px;

      .total-result__saving--per-month,
      .total-result__emission--per-month {
        grid-row: 1;
      }

      .total-result__emission {
        margin-top: 0;
      }
    }

    .total-result__extra {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
    }
  }

  @include phone {
    .total-result__savings {
      display: block;

      .total-result__emission {
        margin-top: 8px;
      }
    }
    .total-result__extra {
      display: block;
    }
  }
}
