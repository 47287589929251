@mixin tablet() {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin phone() {
  @media (max-width: 479px) {
    @content;
  }
}
