fieldset {
  border: none;
  padding: 0;
  margin: 0 0 16px;

  legend {
    font-weight: 700;
    margin: 0 0 16px;
    padding: 0;
  }
}

label {
  display: block;
  margin: 0 0 8px;
  padding: 0;
}

.form-item {
  margin-bottom: 16px;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
select {
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #000000;
  outline: none;
  appearance: none;
}

select {
  padding-right: 36px;
  background: url("../images/icon-expand.svg") no-repeat right 8px center;
}

textarea {
  height: auto;
}
