@use 'typography';

.header {
  position: absolute;
  left: 26px;
  top: 26px;

  .logo {
    width: 95px;
  }
}
