@use 'variables' as *;
@use 'mixins' as *;
@use 'typography';
@use 'button';

html {
  height: 100%;
}

body {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 280px) no-repeat;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a, a:visited {
  color: $color-black;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0 0 0 16px;
}

.app {
  position: relative;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.add-to-home {
  position: fixed;
  bottom: 16px;
  left: 16px;
}

.add-to-home__popup {
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  min-width: 280px;
  z-index: 10;
  padding: 20px;
  background: #f2f8ff;
  border-radius: 10px;
  line-height: 20px;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));

  svg {
    vertical-align: middle;
    max-height: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #f2f8ff;
  }

  &.ipad {
    bottom: auto;
    top: 36px;

    &:after {
      transform: scaleY(-1);
      top: auto;
      bottom: 100%;
    }
  }
}

.screen {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 20px;
  overflow: hidden;

  &.input-form {
    .screen__header {
      min-height: 270px;
      padding-top: 46px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 354px;
        left: 50%;
        top: 0;
        right: 0;
        margin-left: -8px;
        background: url("../images/hero.png") no-repeat;
        background-size: 581px 354px;
        pointer-events: none;
      }
    }
  }
}

.screen__header {
  align-self: stretch;
  position: relative;
  text-align: center;

  .intro {
    max-width: 245px;
    margin: 14px auto 0;
    @extend .small-text-bold;
  }
}

.input-form__form {
  max-width: 350px;
  width: 100%;

  .form-actions {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;

    button[type="submit"] {
      @extend .button--splash;
    }
  }

  .input-form__error {
    color: $color-form-error;
    margin-bottom: 16px;
  }

  @include tablet {
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.results__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
}

.results__form {
  .form-actions {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;

    button[type='reset'] {
      @extend .button--splash;
    }
  }
}

.terms {
  padding: 26px;
  text-align: center;
  margin: 0 auto;
  max-width: 560px;

  .handle {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &:after {
      display: inline-block;
      content: '';
      width: 18px;
      height: 18px;
      background: url('../images/icon--plus.svg') no-repeat;
      background-size: contain;
      margin-left: 4px;
    }
  }

  .terms__inner {
    text-align: initial;
    font-size: 11px;
    line-height: 15px;
    max-height: 0;
    overflow: hidden;

    p {
      margin: 0;

      a {
        font-weight: 600;
      }
    }

    p + p {
      margin-top: 15px;
    }
  }

  &.open {
    .handle {
      margin-bottom: 25px;

      &:after {
        background-image: url('../images/icon--minus.svg');
      }
    }

    .terms__inner {
      max-height: none;
    }
  }
}