@use "variables" as *;
@use "typography";

.car {
  max-width: 210px;
  padding: 32px;

  &.car--new {
    position: relative;
    background: $color-light-gray;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -184px;
      top: -212px;
      width: 431px;
      height: 512px;
      background: url("../images/car-splash.svg") no-repeat;
      background-size: contain;
      z-index: -1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: -52px;
      width: 139px;
      height: 55px;
      background: url("../images/car-splash--bottom.svg") no-repeat;
      background-size: contain;
      z-index: -1;
    }
  }

  &.car--current {
    .car__image {
      img {
        transform: scaleX(-1);
      }
    }
  }

  &__name {
    @extend .small-text-bold;
    line-height: 20px;
    margin-bottom: 16px;

    .form-item-select {
      label {
        display: none;
      }

      select {
        @extend .small-text-bold;
        padding: 0 20px 0 0;
        border: 0;
        height: 20px;
        width: auto;
        max-width: 100%;
        background: url("../images/icon-expand.svg") no-repeat right center;
      }
    }
  }

  &__image {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba($color-black, 0.2);

    img {
      display: block;
      width: 100%;
    }
  }

  &__costs {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;

    span {
      @extend .small-text;
      vertical-align: middle;
    }
  }

  &__emissions {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    h3 {
      font-weight: 400;
    }

    span {
      @extend .small-text;
      vertical-align: middle;
    }
  }
}
