@use 'variables' as *;

body {
  font-family: 'Century Gothic', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: $color-black;
}

.small-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.small-text-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

h2, .h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
}

h3, .h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

