@use 'variables' as *;

.button,
input[type="submit"],
button {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding: 9px 48px;
  color: $color-white;
  background-color: $color-black;
  border-radius: 32px;
  border: 1px solid $color-black;
  outline: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;

  svg {
    display: block;
    height: 32px;
  }
}

.button--icon {
  padding: 8px;
}

.button--outline {
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-black;
}

.button--splash {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 76px;
    height: 30px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -4px);
    background: url("../images/splash.png") no-repeat;
    background-size: contain;
  }
}
